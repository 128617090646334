import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { AppStandardButton } from "../../components/UI/AppButtons";
import NotAuthorisedPage from "../notAuthorisedPage";
import { t } from "i18next";

export default function CustomerDetailsList() {
  const navigateTo = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [data, setCustomerDetails] = useState([]);

  // const Clicked = (customerId) => {
  //   localStorage.setItem("customerId", customerId);
  //   navigateTo("/updateCustomer");
  // };

  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowCustomerDetails(e.reservedArea);
    });
  }, []);

  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    const offset = page * rowsPerPage;
    BookingApi.getCustomerDeatilsByUserid(id, offset, rowsPerPage).then((e) => {
      setTotalCustomers(e.data.total);
      setCustomerDetails(e.data.customerList);
    });
  }, [page, rowsPerPage]);

  return (
    <div>
      {showCustomerDetails ? (
        <div className="flex">
          <div className="!w-780">
            <BookingDetalisListSideNavigation />
          </div>
          <div className="w-[72%] rounded-sm pt-12">
            <div className="text-[#333] px-8 max-w-screen-xl m-auto">
              <h1 className="text-[28px] font-medium leading-snug">
                {t("Customer Details")}
              </h1>
              <h3 className="text-[#838181] text-[20px]">
                {t("Here is your customer details")}
              </h3>
            </div>
            <TableContainer className="!shadow-grey-400 !rounded-2xl !border-solid !border-3 p-8">
              <Table
                className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400"
                aria-label="simple table"
                stickyHeader
              >
                <TableHead className="!bg-[#0693e3] text-[24px] rounded-2xl ">
                  <TableRow className="!bg-[#0693e3] mx-12 ">
                    <TableCell className="!bg-[#0693e3] !text-[#fff] !text-[20px] rounded-tl-2xl ">
                      {t("Full name")}
                    </TableCell>
                    <TableCell className="!bg-[#0693e3] !text-[#fff] !text-[20px]">
                      {t("Email")}
                    </TableCell>
                    <TableCell className="!bg-[#0693e3] !text-[#fff] !text-[20px]">
                      {t("Mobile Number")}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="!bg-[#0693e3] !text-[#fff] !text-[20px] pl-12 rounded-tr-2xl"
                    >
                      {t("Company Name")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((e: any, i) => (
                    <TableRow
                      key={i}
                      // onClick={() => Clicked(e.id)}
                      // style={{ cursor: "pointer" }}
                    >
                      <TableCell
                        className="!text-[16px] rounded-bl-2xl"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          <li>
                            {e.name || ""} {e.surname}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell className="!text-[16px]">
                        {e.email || ""}
                      </TableCell>
                      <TableCell className="!text-[16px]">
                        {e.phoneNumb1 || ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="!text-[16px] rounded-br-2xl"
                      >
                        {e.isPhysicalPerson
                          ? e.name + " " + e.surname
                          : e.companyName || ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalCustomers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
