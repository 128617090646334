import {
  BookingFormConfiguration,
  chooseVehicleConfiguration,
  FooterConfiguration,
  HeaderConfiguration,
  HomePageConfiguration,
} from "../../@types/Pages/PageConfiguration";
import chooseVehicleConfig from "../../API/responses/ChooseVehicleConfig.json";
export interface PageConfigurations {
  homePage: HomePageConfiguration | {};
  header: HeaderConfiguration;
  footer: FooterConfiguration;
  chooseVehicle: chooseVehicleConfiguration;
  paymentDetails: {};
  bookingConfirm: {};
  bookingDeclined: {};
  bookingForm: BookingFormConfiguration | {};
  chooseVehicleCard: {};
}
const allPageConfigurations: PageConfigurations = {
  homePage: {},
  header: {
    isHidden: false,
  },
  footer: {
    isHidden: true,
  },
  chooseVehicle: chooseVehicleConfig,
  paymentDetails: {},
  bookingConfirm: {},
  bookingDeclined: {},
  bookingForm: {},
  chooseVehicleCard: {},
};

export { allPageConfigurations as default };
