import AppStandardButton from "../../components/UI/AppButtons";
import { BookingInformationRow } from "../../components/Layouts/BookingInformation/BookingInformation";
import React, { useEffect } from "react";
import { t } from "i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { BookingApi } from "../../API/Apis/BookingApi";
import { StripeTransactionDetails } from "../../@types/Booking/stripeTransactionDetails";

export function BookingConfirmPageStripe() {
  // const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const stripePageRedirectionUrl: StripeTransactionDetails = {
    sessionId: searchParams.get("sessionId") || "",
    priceId: searchParams.get("priceId") || "",
    reservationId: searchParams.get("reservationId") || "",
    userId: searchParams.get("userId") || "",
  };

  useEffect(() => {
    BookingApi.savePaymentForStripe(stripePageRedirectionUrl);
  }, []);

  const handleCallback = () => {
    fetch("")
      .then((response) => {})
      .catch((error) => {});
  };
  return (
    <section className="min-h-[80vh] box-border px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header
        className="grow flex flex-col items-center justif-center gap-4 box-border py-8"
        style={{ backgroundColor: "white" }}
      >
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1 className="text-3xl font-bold text-[#000000] py-2">
          {t("Congratulations! Your Booking Is Completed")}
        </h1>
        <p className="font-bold text-black-600">
          {/* Reservation Code:{" "} */}
          {/* Web Check In Code:{" "}
            <span className="font-bold text-[#5cbc14]">DG-87450</span> */}
        </p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-8"}
            variant="contained"
            color="white"
            style={{ borderRadius: "5" }}
          >
            {/* Go to Account */}
            <span className="normal-case text-[#0693e3] p-1 text-lg">
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-8"}
            color="white"
            variant="contained"
            style={{ borderRadius: "5" }}
          >
            {/* Go to Account */}
            <span className="normal-case text-[#0693e3] p-1 text-lg">
              {" "}
              Dashboard
            </span>
          </AppStandardButton>
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        {/* Web Checkin Info */}
        {/* Web Checkin Information */}

        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
