import { CloseTwoTone, DoneTwoTone } from "@mui/icons-material";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import { AppStandardButton as AppButton } from "../../UI/AppButtons";
import { InsuranceCardProps } from "./Insurance.props";
import React, { useState } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
function InsuranceCardsLayout({
  insurances,
  onSelected,
  selectedInsuranceIndex = -1,
}: InsuranceCardProps) {
  const allInsuranceFeatures: Record<string, boolean> = {};
  const allInsurances = insurances.map((el) => {
    const availableInsurances = el.features.reduce((prev, current) => {
      const feature = current.toLowerCase();
      prev[feature] = true;
      allInsuranceFeatures[feature] = true;
      return prev;
    }, {});
    return { ...el, availableInsurances };
  });
  const { t: translate } = useTranslation();
  const secondaryTextColor = " text-blue-600 ";
  const isSmallScreen = window.innerWidth <= 768;
  const currencyDetails = BookingGetters.getCurrency();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const handleCardClick = (index: number) => {
    if (expandedIndex == index) {
      setExpandedIndex(-1);
      onSelected(-1);
    } else {
      setExpandedIndex(index);
      onSelected(index);
    }
  };
  const border = " border-[0.5px] border-solid border-slate-600 ";
  return isSmallScreen ? (
    <div>
      {allInsurances.map((el, index) => (
        <div
          key={index}
          className={`shadow-lg m-4 p-2 cursor-pointer rounded-3xl bg-white`}
          style={{ width: "99%", textAlign: "center" }}
          onClick={() => handleCardClick(index)}
        >
          <h5 className="text-xl font-semibold text-center mb-2">{el.name}</h5>
          <p className={`text-lg mb-2 font-bold`}>
            {formatPriceWithCurrencySymbol(currencyDetails).symbol + " "}
            {el.pricePerDayInUSD}
          </p>
          <div className="text-center">
            {Object.keys(allInsuranceFeatures).map((feature, i) => (
              <div key={i} className={`flex items-left mb-2`}>
                {el.bookingText &&
                el.bookingText[
                  feature.toUpperCase() + ":" + el.name.toUpperCase()
                ] ? (
                  <div
                    style={{ whiteSpace: "pre-line", fontSize: "small" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        el.bookingText[
                          feature.toUpperCase() + ":" + el.name.toUpperCase()
                        ],
                    }}
                  />
                ) : (
                  <>
                    {el.availableInsurances[feature] ? (
                      <DoneTwoTone className="text-green-800 font-bold mr-2" />
                    ) : (
                      <CloseTwoTone className="text-red-800 mr-2" />
                    )}
                    <p className="text-md" style={{ textAlign: "left" }}>
                      {feature}
                    </p>
                  </>
                )}
              </div>
            ))}
          </div>
          <div style={{ textAlign: "-webkit-center" }}>
            <AppButton
              className="h-10 w-30 "
              color={selectedInsuranceIndex == index ? "success" : "primary"}
              style={{ borderRadius: "5" }}
            >
              <p className={`w-full text-center`}>{t("Select")}</p>
            </AppButton>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <table className="table-auto  border-collapse text-black sm:w-full">
      <thead>
        <tr className={border}>
          <th className={border + ""}>
            <h3>{t("Pricing Table")}</h3>
          </th>

          {allInsurances.map((el, index) => (
            <th
              className={border + "sm:min-w-auto h-20 sm:relative"}
              key={index}
            >
              {el.isRecommended && (
                <div className="text-grey-200 font-light">
                  <img
                    src="/assets/images/Favo-icon@2x.png"
                    className="h-12 w-12 top-0 absolute"
                  />
                </div>
              )}
              <h5 className="sm:text-sm">{el.name}</h5>
              <p className={secondaryTextColor + "sm:text-md font-normal"}>
                {formatPriceWithCurrencySymbol(BookingGetters.getCurrency())
                  .symbol + " "}
                {el.pricePerDayInUSD}
              </p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="overflow-scroll w-full">
        {Object.keys(allInsuranceFeatures).map((el, index) => {
          return (
            <tr
              key={index}
              className={border + "sm:font-semibold text-sm capitalize"}
            >
              <td className={border + " sm:min-w-[12rem] box-border p-1"}>
                {el}
              </td>

              {allInsurances.map((insurance, i) => {
                const text =
                  insurance.bookingText &&
                  insurance.bookingText[
                    el.toUpperCase() + ":" + insurance.name.toUpperCase()
                  ];
                if (text != null && text !== "undefined" && text !== "") {
                  return (
                    <td className={border + ""} key={i}>
                      <p className="h-full w-full flex justify-center items-center">
                        {text}
                      </p>
                    </td>
                  );
                } else if (
                  insurance.availableInsurances &&
                  insurance.availableInsurances[el]
                ) {
                  return (
                    <td className={border + ""} key={i}>
                      <p className="h-full w-full flex justify-center items-center">
                        <DoneTwoTone className="text-green-800 font-bold" />
                      </p>
                    </td>
                  );
                } else {
                  return (
                    <td className={border} key={i}>
                      <p className="h-full w-full flex justify-center items-center">
                        <CloseTwoTone className="text-red-800" />
                      </p>
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot className="">
        <tr>
          <td className={border + " h-20"}>
            <AppButton
              clickHandler={() => {
                onSelected(-1);
              }}
              color="gray"
              className="box-border px-6 text-white"
            >
              {/* Cancel */}
              {t("Cancel")}
            </AppButton>
          </td>
          {allInsurances.map((el, index) => (
            <td className={border + "relative"} key={index}>
              <AppButton
                color={selectedInsuranceIndex == index ? "success" : "primary"}
                clickHandler={() => {
                  onSelected(index);
                }}
                className="box-border px-6"
              >
                {/* Choose */}
                {t("Choose")}
              </AppButton>
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}
export { InsuranceCardsLayout };
