import {
  createSearchParams,
  NavigateFunction,
  useNavigate,
} from "react-router-dom";

import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingForm from "../../components/Layouts/BookingForm/BookingForm";
import { PageConfigGetters } from "../../store/PageConfigurations/getters";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BookingApi from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function HomePage(): JSX.Element {
  const { t: translate } = useTranslation();
  const homePageConfig = PageConfigGetters.getHomePageConfig();
  const BookingFormConfig = PageConfigGetters.getBookingFormConfig();
  const dispatch = useDispatch();
  const routerNavigation: NavigateFunction = useNavigate();
  useEffect(() => {
    dispatch(BOOKING_ACTIONS.setSessionExpiryTime(0));
  }, []);
  const [showBooking, setShowBooking] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowBooking(e.booking);
      // setShowBooking(true);
    });
  }, [showBooking]);

  return (
    <div className="min-h-screen flex flex-col">
      <section className="h-full  box-border flex flex-col md:px-12 px-4 ">
        <header className="font-bold box-border flex flex-row items-end mb-10 ">
          {/* <div
            className={`w-full ${t(
              homePageConfig.descriptionTextClasses || ""
            )}`}
          >
            <h2 className="text-5xl">{t(homePageConfig.title)}</h2>
            <h4 className="md:w-1/5 w-full  py-4">
              {t(homePageConfig.subTitle)}
            </h4>
          </div> */}
        </header>
        {showBooking ? (
          <div className="min-h-24 h-fit sm:flex flex-row ">
            {BookingFormConfig.fields && (
              <BookingForm
                className="grow px-4 pl-0"
                options={BookingFormConfig.fields || []}
                onSubmit={(e) => {
                  dispatch(
                    BOOKING_ACTIONS.setBookingInformation({
                      ...e,
                    })
                  );

                  const searchParams = createSearchParams({
                    dropOffDateAndTime: e.dropOffDateAndTime,
                    pickUpDateAndTime: e.pickUpDateAndTime,
                    pickUpLocation: e.pickUpLocationDetails.code,
                    dropOffLocation: e.dropOffLocationDetails.code,
                    reservationSource: e.reservationSource.code,
                    macroGroupName: e.macroGroupName,
                    // discountValueWithoutVat: e.discountValueWithoutVat,
                    agreementCoupon: e.agreementCoupon,
                    showPics: "true",
                    showOptionalImage: "true",
                    showVehicleParameter: "true",
                    showVehicleExtraImage: "true",
                    showBookingDiscount: "true",
                    isYoungDriverAge: e.isYoungDriverAge,
                    isSeniorDriverAge: e.isSeniorDriverAge,
                    noFeeAge: e.noFeeAge,
                    isAgeLimitSelected: e.isAgeLimitSelected,
                  }).toString();
                  routerNavigation("/choose/vehicle?" + searchParams);
                }}
              />
            )}
          </div>
        ) : (
          <NotAuthorisedPage />
        )}
        {/* <div className="min-h-24 h-fit flex flex-row ">
          {BookingFormConfig.fields && (
            <BookingForm
              className="grow px-8"
              options={BookingFormConfig.fields || []}
              onSubmit={(e) => {
                dispatch(
                  BOOKING_ACTIONS.setBookingInformation({
                    ...e,
                  })
                );
                routerNavigation("/choose/vehicle");
              }}
            />
          )}
        </div> */}

        {/* <p
          className={
            "text-sm font-bold box-border pt-4" +
            " " +
            homePageConfig.descriptionTextClasses
          }
        >
          {t(homePageConfig.disclaimer)}
        </p> */}
      </section>
    </div>
  );
}
export default HomePage;
